.comingsoon h2{
    font-size: 40px;
color: #fff;
}

.comingsoon{
    justify-content: center;
    display: flex;
    align-items: center;
    height: calc(100vh - 160px);
}

.main_coming{
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.9) 100%);

}

@media (max-width:767px) {
    .comingsoon{
        justify-content: center;
        display: flex;
        align-items: center;
        height: 100vh;
    }

    .comingsoon h2{
        font-size: 30px;
    color: #fff;
    }
    
    
}