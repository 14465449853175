@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');

header {
  font-family: 'Open Sans';
}

li {
  margin: 0;
  /* list-style: none; */
}

.navbar {
  gap: 4rem;
  width: 90%;
  margin: auto;
  padding: 2rem 1rem;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.menu-list {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 300ms ease-in-out;
}


.menu-list li a:hover {
  cursor: pointer;
  color: var(--golden-100);
}

.hamberger-menu {
  display: none;
}

.close-icon {
  display: none;
  color: white;
  font-size: 4rem;
}

.mob-nav {
  display: none;
}

.operator-btn {
  display: none;
}


.btn {
  cursor: pointer;
  background: linear-gradient(89.98deg, #F2F37D -9.38%, #D8AA2E 17.23%, #D8AA2E 80.75%, #F2F17C 112.96%);
  border-radius: 10px;
  width: max-content;
  align-self: center;
  border: none;
  color: #ffffff;
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  padding: .5rem 1.5rem;
}


@media screen and (max-width: 1024px) {
  .navbar {
    width: 95%;
  }

  .menu-list {
    width: 85%;
  }
}

@media screen and (max-width:800px) {
  header {
    background-color: black;
  }

  .navbar {
    padding: 1rem;
  }

  .hamberger-menu {
    display: block;
  }

  .menu-list {
    display: none;
  }

  .mob-nav {
    gap: 1rem;
    top: 100%;
    right: 0;
    width: 200px;
    z-index: 10;
    display: flex;
    padding: 1rem 0 2rem 3rem;
    border-radius: 20px;
    flex-direction: column;
    position: absolute;
    align-items: flex-start;
    background: black;
    border: 1px solid var(--golden-100);
  }

  .close-icon {
    display: block;
  }

  .operator-btn {
    display: block;
  }

}