.modalOverlay {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  font-size: 4rem;
  line-height: 4.375rem;
  text-align: center;
  color: #ffffff;
  background: var(--golden-100);
  border-radius: 1.5rem;
  padding: 6.813rem 5.375rem;
  box-sizing: border-box;
}

@media (max-width: 500px) {
  .modalContent {
    padding: 5rem 4rem;
    line-height: 6rem;
    width: 90%;
  }
}
