@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chivo:wght@500&family=Orbitron:wght@400;600;700&family=Outfit:wght@500;700&family=Syne:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Acme&family=Chivo:wght@500&family=Orbitron:wght@400;600;700&family=Outfit:wght@500;700&family=Syne:wght@700&display=swap");
@font-face {
  font-family: Segoe UI;
  src: url("./assets/font/SegoeUI.ttf");
}

@font-face {
  font-family: Avenir;
  src: url("./assets/font/Avenir.otf");
}

@font-face {
  font-family: ChakraPetch;
  src: url("../public/font/ChakraPetch-Regular.ttf");
}

body,
html{
  /* position: relative; */
   /* scroll-behavior: smooth;  */
  background: #000;
}

.stopscrollpadding{
  scroll-margin-top: 45rem;
  /* scroll-snap-align:start !important */
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* For WebKit (Chrome, Safari, Edge) */

#input_range{
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  outline: none;
  border-radius: 10px;
  border: none;
}

#input_range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: #DAA200;
  border-radius: 50%;
}
#input_range::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2);
}
#input_range::-webkit-slider-thumb:active {
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5);
}

/* For WebKit (Chrome, Safari, Edge) */
#input_range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: #DAA200;
  border-radius: 50%;
}

#input_range::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2);
}

#input_range::-webkit-slider-thumb:active {
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5);
}

/* For Firefox */
#input_range::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background: #DAA200;
  border-radius: 50%;
  border: none;

}

#input_range::-moz-range-progress {
  background-color:transparent
}

#input_range::-moz-range-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2);
  border: none;

}

#input_range::-moz-range-thumb:active {
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5);
  
}


input {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* margin: 0; */
}

input:-webkit-autofill {
  /* Specify the desired color for the text */
  -webkit-text-fill-color: var(--grey);
  /* Specify the desired background color */
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

/* Hide the scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Add custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #c49507;
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background-color: #000000;
}

:root {
  --golden-100: #d8aa2e;
  --black-100: #000000;
  --yellow: #d8aa2e;
  --grey: #949494e0;
  --box-bg: #272727d9;
  --white: #ffffff;
}

.create-token-wrapper {
  min-height: 100vh;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.08) 100%),
    url("./assets/images/createToken/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top, center;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.create-token-wrapper * {
  margin: 0px;
  padding: 0px;
  font-family: "Segoe UI", sans-serif;
  list-style: none;
  box-sizing: border-box;
}

.blinking-square {
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: var(--yellow);
  animation: blink 2.5s infinite;
  filter: blur(0.7px) brightness(0.6);
}

.square1 {
  top: 60%;
  left: 2%;
}

.square2 {
  bottom: 30%;
  left: 4%;
}

.square3 {
  bottom: 15%;
  left: 20%;
}

.square4 {
  bottom: 15%;
  left: 40%;
}

.square5 {
  bottom: 15%;
  left: 70%;
}

.square6 {
  top: 60%;
  right: 2%;
}

.square7 {
  bottom: 20%;
  right: 4%;
}

.square8 {
  bottom: 40%;
  left: 30%;
}

.square9 {
  bottom: 40%;
  right: 30%;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 1300px) {
  :root {
    /* font-size: 14px; */
  }
}

@media screen and (max-width: 1000px) {
  :root {
    /* font-size: 12px; */
  }
}

@media screen and (max-width: 800px) {
  /* :root {
    font-size: 10px;
  } */

  .blinking-square {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 400px) {
  /* :root {
    font-size: 8px;
  } */
}

.custom-bg {
  background-image: url("../public/backBG.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center
}
.overlaybgfornft{
  background: linear-gradient(180deg, rgba(17, 17, 17, 0.00) 34.18%, #111 64.31%);
}


@layer components {
  .AllPading {
    @apply max-w-7xl m-auto;
  }

  .sButton {
    @apply px-10 py-5 bg-gradient-to-r from-[#DAA200] to-[#FFD600] justify-start items-start gap-2.5 inline-flex rounded-lg;
  }
  .bText {
    @apply text-neutral-900 text-base font-bold  leading-normal;
  }
  .systeamGrids {
    @apply systeamGrid;
  }
  .header_link_li {
    @apply text-base font-normal  leading-[18px] text-white; 
  }
  .nav_link_li{
    @apply text-zinc-100 text-sm  py-1 flex  pr-4 pl-3 md:p-0 flex-col md:mt-0 md:flex-row md:space-x-8 md:font-medium font-normal  leading-normal px-2 xl:px-0 
  }
  .stake_btn{
    @apply  transition-all sm:w-[150px] hover:opacity-70 font-semibold text-white  sm:h-[44px] w-auto h-auto sm:py-0 py-2 bg-gradient-to-r from-[#DAA200] to-[#FFD600]  rounded-[10px] flex sm:flex-initial flex-1 items-center justify-center   disabled:bg-slate-500 disabled:cursor-not-allowed
  }
  .bg_btn_gr {
    @apply bg-gradient-to-r from-yellow-500 to-[#FFCC70]
   }
   .swapBtn{
    @apply transition-all hover:opacity-70 font-semibold w-full text-white   py-5 swapbtn rounded-[5px] flex items-center justify-center   disabled:bg-slate-500 disabled:cursor-not-allowed
   }
   .box_style_nft_layers{
    @apply box_style_nft_layer
   }
   .stopscrollmargin{
    @apply stopscrollpadding
   }
}

.box_style_nft_layer::before {
  content: "";
  display: block;
  width: 80px;
  height: 103px;
  background-image: url("../public/Nft/box/left.png");
  background-size:contain ;
  background-repeat:no-repeat;
  position: absolute;
  left: -82px;
  z-index: 0;
}
.box_style_nft_layer{
  margin-top: 100px !important;
}
.box_style_nft_layer::after {
  content: "";
  display: block;
  width: 80px;
  height: 103px;
  background-image: url("../public/Nft/box/right.png");
  background-size:contain ;
  background-repeat:no-repeat;
  position: absolute;
  right: -82px;
  z-index: 0;
  top: 0;
}
.systeamGrid {
  display: grid;
  grid-template-columns: auto 1fr;
}

.Features:nth-child(even) {
  margin-top: 55px !important;
}
@media screen and (max-width: 767px) {
  .Features:nth-child(even) {
    margin-top: 0px !important;
  }
}
/* Custom CSS for responsiveness */
@media (max-width: 767px) {
  .custom-wrap {
    white-space: normal;
    word-break: break-all;
  }
}

.border-left-right::before,
.border-left-right::after {
  content: "";
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  background-color: red;
  z-index: -1;
}

.border-left-right::before {
  left: 0;
}

.border-left-right::after {
  right: 0;
}
@keyframes textShine {
  to {
    background-position: 200% center;
  }
}
/* Webkit (Chrome, Safari, newer versions of Opera) */
#scrollableDiv::-webkit-scrollbar {
  width: 0px; /* Adjust as needed */
}

#scrollableDiv::-webkit-scrollbar-thumb {
  background-color: #888; /* Adjust as needed */
}

#scrollableDiv::-webkit-scrollbar-track {
  background-color: transparent; /* Adjust as needed */
}

.textShineSlow {
  text-align: center;
  background: linear-gradient(
  to right,
  #7149df 20%,
  #FF9800 40%,
  rgb(254 254 254) 60%,
  #FFEB3B 80%
);
  background-size: 200% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textShine 5s linear infinite;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border-width: 0;
}

.smooth-scroll {
  transition: scroll-behavior 500ms ease-in-out;
}
.popup-content {

  /* background: none !important;
 border: none !important;
  width: 100% !important; */
  width: fit-content !important;
  border: none !important;
  background: none !important
}
.swapbox {
  background: linear-gradient(180deg, #1d1802 0%, #212121 100%);
}
.swapbtn{
  background: linear-gradient(99deg, #DAA200 0%, #FFD600 100%);
}
