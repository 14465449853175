.box_style_nft {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  backdrop-filter: blur(12.5px);
}

.box_style_nft_layer::before {
    content: "";
    display: block;
    width: 80px;
    height: 103px;
    background-image: url("../public/Nft/box/left.png");
    background-size:contain ;
    background-repeat:no-repeat;
    position: absolute;
    left: -83px;
    z-index: 0;
}


#nftminter{
  scroll-padding-top:400px !important;
}
/* @media(max-width:767px){
 #nftminter{
    scroll-padding-top:200px !important;
 }
} */